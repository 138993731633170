.swiper-container {
    max-width: 1200px;
    width: 100%;
    position: static;
}

.btn {
    padding: 13px;
    background-color: #fff;
    color: #000;
    z-index: 1;
    outline: none;
    border: none;
    border-right: 2px solid #d2d2d2;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    min-width: 300px;
    width: 100%;
}

.map__year {
    background: #FFFFFF;
    border-radius: 15px 15px 0 0;
    max-width: 91px;
    text-align: center;
    padding: 5px 30px;
    font-weight: 400;
    font-size: 16px;
}

.map_slide {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.map_slide:hover>.btn {
    background-color: #ECDBBE;
    color: #fff;
    font-weight: 500;
}

.map_slide:hover>.map__year {
    background-color: #ECDBBE;
    color: #fff;
    font-weight: 500;
}

.active>.btn ,
.active>.map__year {
    background-color: #ECDBBE;
    color: #fff;
    font-weight: 500;
}

.map__slider {
    z-index: 2;
    position: absolute;
    bottom: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    width: 100%;
    
}

.map__slider-wrapper {
    display: flex;
    align-items: flex-end;
    position: relative;
}



.swiper-button-next {
    background-image: url(./img/map__arrow-r.svg);
    background-repeat: no-repeat;
    background-position: center;
    max-width: 10px;
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    padding: 20px;
    border-radius: 0 15px 15px 0;
    right: -40px;
    top: inherit;
    bottom: 0;
  }
  
  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-next:hover {
    background-color: #ECDBBE;
  }

  .swiper-button-prev {
    background-image: url(./img/map__arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    max-width: 10px;
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    padding: 20px;
    border-right: 2px solid #d2d2d2;
    border-radius: 15px 0 0 15px;
    left: -42px;
    top: inherit;
    bottom: 0;

  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-prev:hover {
    background-color: #ECDBBE;
  }

  @media(max-width:1440px) {
    .swiper-container {
      max-width: 900px;
    }
  }

  @media(max-width:1000px) {
    .map__slider {
      bottom: 110px;
    }
    .swiper-container {
      max-width: 600px;
    }
  }

  @media(max-width: 768px) {
    .swiper-container {
      max-width: 400px;
    }
    .btn {
      min-width: 200px
    }
  }

  @media(max-width: 520px) {
    .swiper-container {
      max-width: 320px;
    }
  }
  
  @media(max-width: 480px) {
    .swiper-container {
      max-width: 240px;
    }
    .btn {
      min-width: 240px
    }
  }

  @media(max-width: 360px) {
    .map__slider {
      bottom: 90px;
    }
    .swiper-container {
      max-width: 200px;
    }
    .btn {
      min-width: 200px
    }
  }
