.MenuToggle {
    position: fixed;
    top: 40px;
    right: 40px;
    font-size: 3rem;
    cursor: pointer;
    z-index: 100;
    transition: opacity,right .22s ease-in;
}

.MenuToggle:hover {
    color: blue;
}

.MenuToggle.open {
    right: 40px;
}

@media (max-width: 1720px) {
    .MenuToggle {
        top: 30px;
        right: 30px;
        font-size: 2.5rem;

    }
    .MenuToggle.open {
        right: 30px;
    }
}

@media (max-width:1300px) {
    .MenuToggle {
        top: 20px;
        right: 20px;
        font-size: 2rem;

    }
    .MenuToggle.open {
        right: 20px;
    }
}
@media (max-width:1000px) {
    .MenuToggle {
        font-size: 2.5rem;
    }
}


@media (max-width:520px) {
    .MenuToggle {
        top: 15px;
        right: 15px;
        font-size: 2rem;
    }
    .MenuToggle.open {
        right: 15px;
    }
}
