.links {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 290px;
  height: 100%;
  max-width: 40px;
  margin-right: 50px;
}

.text {
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  height: 84px;
  line-height: 1;
  user-select: none;
}

@media (max-width: 1720px) {
  .links {
    margin-right: 40px;
    max-height: 280px;
  }
  .text {
    height: 75px;
  }
}

@media (max-width: 1300px) {
  .links {
    margin-right: 30px;
    max-height: 260px;
  }
  .text {
    height: 65px;
  }
}

@media (max-width: 1000px) {
  .links {
    max-height: 100px;
    margin-right: 0;
    position: absolute;
    left: 10%;
    bottom: 90px;
  }
  .text {
    display: none;
  }
}
