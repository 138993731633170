.divider {
  height: 26px;
  width: 1px;
  background-color: #000;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

@media (max-width: 1720px) {
  .item:nth-child(1) .link {
    width: 13px;
    height: 23px;
  }
  .item:nth-child(2) .link {
    width: 20px;
    height: 12px;
  }
  .item:nth-child(3) .link {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 1000px) {
  .divider {
    display: none;
  }
}

@media (max-width: 520px) {
  .item:nth-child(1) .link {
    width: 12px;
    height: 20px;
  }
  .item:nth-child(2) .link {
    width: 20px;
    height: 11px;
  }
  .item:nth-child(3) .link {
    width: 17px;
    height: 17px;
  }
}

@media (max-height: 520px) {
  .item:nth-child(1) .link {
    width: 12px;
    height: 20px;
  }
  .item:nth-child(2) .link {
    width: 20px;
    height: 11px;
  }
  .item:nth-child(3) .link {
    width: 17px;
    height: 17px;
  }
}
