.wrapper {
    display: flex;
    flex-direction: row;
    width: 425vw;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
}

.outerWrapper {
    height: 100vw;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    position: absolute;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

 ::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1000px) {
    .wrapper {
        width: 900vw;
    }
}