[class*="ymaps-2"][class*="-ground-pane"] {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
}

.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 0;
    position: relative;
}
.map {
    position: relative;
    width: 100vw;
    height: 75vh;
    z-index: 1;
}

.CustomBaloon {
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: 343px;
    width: 100%;
    line-height: 1.5;
    gap: 10px;
}

.Figure {
    border-radius: 15px;
    max-width: 303px;
    width: 100%;
}

.Figure img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.title {
    font-size: 16px;

}

.list {
    font-size: 14px;

}

.text {
    font-size: 14px;

}

.link {
    color: #fff;
    background: #ECDBBE;
    border-radius: 100px;
    display: block;
    padding: 10px 103px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}