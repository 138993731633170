.slide {
    width: 100vw;
    height: 100vh;
}

.backgroundText p {
    position: absolute;
    z-index: 0;
    user-select: none;
    font-size: 10vw;
    color: #f7f8f9;
    font-weight: 700;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}

@media (max-width: 1000px) {
    .wrapper {
        justify-content: center;
        flex-direction: column;
    }
}

@media (max-width:520px) {
    .backgroundText p {
        display: none;
    }
}