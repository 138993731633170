.slide {
  width: 100vw;
  height: 100vh;
  background: #f7f8f9;
}

.background {
  z-index: 0;
  position: absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.background img {
  height: 60vh;
  width: 100vw;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
  height: 100vh;
}

.notebook {
  z-index: 1;
  user-select: none;
}

.arcticle {
  max-width: 455px;
  width: 100%;
  margin-right: 120px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1720px) {
  .notebook img {
    max-width: 600px;
  }
  .arcticle {
    max-width: 355px;
    margin-right: 100px;
    gap: 15px;
  }
}

@media (max-width: 1300px) {
  .notebook img {
    max-width: 450px;
  }
  .arcticle {
    margin-right: 50px;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
    justify-content: center;
  }
  .notebook img {
    max-width: 500px;
    margin-bottom: 130px;
    margin-top: 100px;
  }
  .background img {
    height: 100vw;
    transform: rotate(90deg);
    width: auto;
  }
  .arcticle {
    max-width: 385px;
    margin: 0 30px 100px 30px;
    gap: 0;
  }
  .arcticle p {
    margin: 0;
    margin: 10px 0 50px 0;
  }
}

@media (max-width: 520px) {
  .notebook img {
    max-width: 300px;
    margin-bottom: 100px;
  }
  .arcticle {
    max-width: 290px;
    margin: 0 30px 100px 30px;
  }
}

@media (max-width: 380px) {
  .notebook img {
    max-width: 260px;
  }
  .arcticle {
    max-width: 252px;
  }
  .arcticle p {
    margin: 10px 0 30px 0;
  }
}

@media (max-height: 520px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
  .notebook img {
    max-width: 300px;
    margin-bottom: 100px;
  }
  .arcticle {
    max-width: 290px;
    margin: 0;
  }
}

@media (max-height: 380px) {
  .notebook img {
    max-width: 260px;
  }
  .arcticle {
    max-width: 252px;
  }
  .arcticle p {
    margin: 10px 0 30px 0;
  }
}
