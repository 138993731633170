.nav {
    margin-left: 40px;
    display: flex;
    max-height: 200px;
    height: 100%;
    position: relative;
    max-width: 110px;
    width: 100%;
}

.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-height: 200px;
}

.item {
    display: block;
    line-height: 0;
}

.lines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 200px;
    margin-left: 18px;
}

.line {
    background-color: #000;
    height: 2px;
    width: 20px;
    transition: 2s ease-out;
    -webkit-transition: 2s ease-out;
    -moz-transition: 2s ease-out;
    -o-transition: 2s ease-out;
}

@media (max-width: 1720px) {
    .nav {
        margin-left: 30px;
    }
    .line {
        height: 1px;
    }
}

@media (max-width:1300px) {
    .nav {
        margin-left: 20px;
    }
}

@media (max-width: 1000px) {
    .nav {
        display: none;
    }
}