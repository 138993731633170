.menu {
  max-width: 727px;
  width: 100%;
  max-height: 771px;
  height: 100%;
  position: relative;
}

@media (max-width: 1720px) {
  .menu {
    max-width: 629px;
    max-height: 647px;
  }
}

@media (max-width: 1300px) {
  .menu {
    max-width: 516px;
    max-height: 531px;
  }
}

@media (max-width: 1000px) {
}

@media (max-width: 700px) {
}

@media (max-width: 520px) {
  .menu {
    max-width: 336px;
    max-height: 346px;
  }
}

@media (max-width: 380px) {
  .menu {
    max-width: 284px;
    max-height: 294px;
  }
}
@media (max-height: 520px) {
  .menu {
    max-width: 336px;
    max-height: 346px;
  }
}

@media (max-height: 380px) {
  .menu {
    max-width: 284px;
    max-height: 294px;
  }
}
