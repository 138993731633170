.el {
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 40px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

@media (max-width: 1720px) {
  .el img {
    width: 27px;
    height: 27px;
  }
  .el {
    bottom: 30px;
    left: 30px;
  }
}

@media (max-width: 1300px) {
  .el img {
    width: 22px;
    height: 22px;
  }
  .el {
    bottom: 20px;
    left: 20px;
  }
}

@media (max-width: 520px) {
  .el {
    bottom: 15px;
    left: 15px;
  }
}

@media (max-width: 380px) {
  .el img {
    width: 20px;
    height: 20px;
  }
}

@media (max-height: 520px) {
  .el {
    bottom: 15px;
    left: 15px;
  }
}

@media (max-height: 380px) {
  .el img {
    width: 20px;
    height: 20px;
  }
}
