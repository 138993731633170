.btn {
  color: #585858;
  background-color: #ecdbbe;
  font-size: 2rem;
  padding: 15px 20px;
  border-radius: 100px;
  display: block;
  max-width: 180px;
  text-align: center;
}

@media (max-width: 1720px) {
  .btn {
    font-size: 1.8rem;
    padding: 10px 15px;
    max-width: 170px;
  }
}

@media (max-width: 1300px) {
  .btn {
    font-size: 1.6rem;
    max-width: 150px;
    padding: 8px 10px;
  }
}

@media (max-width: 1000px) {
  .btn {
    border-radius: 100px;
    padding: 15px 20px;
    font-size: 1.8rem;
    max-width: 170px;
  }
}

@media (max-width: 520px) {
  .btn {
    font-size: 1.5rem;
    max-width: 145px;
    padding: 8px 12px;
  }
}
@media (max-height: 520px) {
  .btn {
    font-size: 1.5rem;
    max-width: 145px;
    padding: 8px 12px;
  }
}
