.headerItem {
  position: absolute;
  top: 40px;
}

.headerItem:nth-child(1) {
  z-index: 1;
  left: 40px;
}

.headerItem:nth-child(2) {
  right: 40px;
}

@media (max-width: 1720px) {
  .headerItem {
    top: 30px;
  }
  .headerItem:nth-child(1) {
    left: 30px;
  }
  .headerItem:nth-child(2) {
    right: 30px;
  }
}

@media (max-width: 1300px) {
  .headerItem {
    top: 20px;
  }
  .headerItem:nth-child(1) {
    left: 20px;
  }
  .headerItem:nth-child(2) {
    right: 20px;
  }
}

@media (max-width: 520px) {
  .headerItem {
    top: 15px;
  }
  .headerItem:nth-child(1) {
    left: 15px;
  }
  .headerItem:nth-child(2) {
    right: 15px;
  }
}

@media (max-height: 520px) {
  .headerItem {
    top: 15px;
  }
  .headerItem:nth-child(1) {
    left: 15px;
  }
  .headerItem:nth-child(2) {
    right: 15px;
  }
}
