* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style: none;
}

html,
body {
  background-color: #fff;
  color: #000;
  line-height: 1.5;
  font-size: 10px;
  overflow: hidden;
}

p {
  font-size: 2rem;
}

img {
  user-select: none;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
  white-space: nowrap;
}

@media (max-width: 1720px) {
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1.6rem;
  }
}

@media (max-width: 1300px) {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.4rem;
  }
}

@media (max-width: 1000px) {
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1.6rem;
  }
}

@media (max-width: 520px) {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
  }
}

@media (max-width: 380px) {
  h1 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1.4rem;
  }
}

@media (max-height: 520px) {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
  }
}

@media (max-height: 380px) {
  h1 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1.4rem;
  }
}
