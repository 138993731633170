.CustomBaloon {
  padding: 20px;
  max-width: 343px;
  width: 100%;
  max-height: 508px;
  height: 100%;
  line-height: 1.5;
  position: absolute;
  right: 40px;
  top: 30px;
  background: #fff;
  z-index: 90;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transform: translateX(383px);
  transition: transform 0.5s ease-in;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 323px;
  width: 100%;
  max-height: 488px;
  height: 100%;
  overflow: scroll;
}

.CustomBaloon.open {
  transform: translateX(0);
}

.Figure {
  border-radius: 15px;
  max-width: 303px;
  width: 100%;
}

.Figure img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.title {
  font-size: 16px;
}

.list {
  font-size: 14px;
}

.text {
  font-size: 14px;
}

.link {
  color: #fff;
  background: #ecdbbe;
  border-radius: 100px;
  display: block;
  padding: 10px 103px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
}
