.el {
  position: absolute;
  bottom: 40px;
  right: 40px;
  background-color: #f7f8f9;
  border-radius: 50px;
  font-size: 2rem;
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 139px;
  width: 100%;
}

.el::after {
  content: '\2192';
  font-size: 28px;
  margin-bottom: 3px;
}

@media (max-width: 1720px) {
  .el {
    padding: 10px 20px;
    max-width: 115px;
    bottom: 30px;
    right: 30px;
  }
  .el::after {
    margin-bottom: 4px;
  }
}

@media (max-width: 1300px) {
  .el {
    padding: 8px 15px;
    max-width: 102px;
    bottom: 20px;
    right: 20px;
  }
}

@media (max-width: 1000px) {
  .el {
    padding: 2px 15px;
  }
}

@media (max-width: 520px) {
  .el {
    padding: 2px 10px;
    max-width: 85px;
    bottom: 15px;
    right: 15px;
  }
  .el::after {
    font-size: 20px;
  }
}

@media (max-height: 520px) {
  .el {
    padding: 2px 10px;
    max-width: 85px;
    bottom: 15px;
    right: 15px;
  }
  .el::after {
    font-size: 20px;
  }
}
