.item {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: #d2d2d2 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 3rem;
}

.item:hover {
  background-color: #ecdbbe;
  color: #fff;
  border: none;
}

.item:nth-child(1) {
  max-width: 222px;
  max-height: 222px;
  left: 2.4%;
  top: 5.58%;
}

.item:nth-child(2) {
  max-width: 163px;
  max-height: 163px;
  left: 43.7%;
  top: 0.52%;
}

.item:nth-child(3) {
  max-width: 270px;
  max-height: 270px;
  right: -2.4%;
  top: 0%;
}

.item:nth-child(4) {
  max-width: 159px;
  max-height: 159px;
  left: -0.7%;
  top: 33.33%;
}

.item:nth-child(5) {
  max-width: 171px;
  max-height: 171px;
  right: -1.9%;
  top: 34.5%;
}

.item:nth-child(6) {
  max-width: 214px;
  max-height: 214px;
  left: 4.4%;
  top: 64.1%;
}

.item:nth-child(7) {
  max-width: 136px;
  max-height: 136px;
  right: 6.9%;
  top: 55.7%;
}

.item:nth-child(8) {
  max-width: 188px;
  max-height: 188px;
  left: 40.99%;
  top: 75.4%;
}

@media (max-width: 1720px) {
  .item {
    font-size: 2.5rem;
  }
  .item:nth-child(1) {
    max-width: 186px;
    max-height: 186px;
    top: 6.5%;
  }
  .item:nth-child(2) {
    max-width: 137px;
    max-height: 137px;
    left: 40%;
    top: 0.2%;
  }
  .item:nth-child(3) {
    max-width: 227px;
    max-height: 227px;
    right: 2.6%;
    top: -2.5%;
  }
  .item:nth-child(4) {
    max-width: 133px;
    max-height: 133px;
    top: 34.33%;
    left: 0;
  }
  .item:nth-child(5) {
    max-width: 144px;
    max-height: 144px;
    right: 0.9%;
    top: 31.5%;
  }
  .item:nth-child(6) {
    max-width: 180px;
    max-height: 180px;
    left: 5.9%;
    top: 64.5%;
  }
  .item:nth-child(7) {
    max-width: 115px;
    max-height: 115px;
    right: 8%;
    top: 53.2%;
  }
  .item:nth-child(8) {
    max-width: 160px;
    max-height: 160px;
    left: 44%;
    top: 74.7%;
  }
}

@media (max-width: 1300px) {
  .item {
    font-size: 2rem;
  }
  .item:nth-child(1) {
    max-width: 153px;
    max-height: 153px;
    top: 6.5%;
  }
  .item:nth-child(2) {
    max-width: 112px;
    max-height: 112px;
    left: 40%;
    top: 0.2%;
  }
  .item:nth-child(3) {
    max-width: 186px;
    max-height: 186px;
    right: 2.6%;
    top: -2.5%;
  }
  .item:nth-child(4) {
    max-width: 109px;
    max-height: 109px;
    top: 34.33%;
    left: 0;
  }
  .item:nth-child(5) {
    max-width: 118px;
    max-height: 118px;
    right: 0.9%;
    top: 31.5%;
  }
  .item:nth-child(6) {
    max-width: 147px;
    max-height: 147px;
    left: 5.9%;
    top: 64.5%;
  }
  .item:nth-child(7) {
    max-width: 94px;
    max-height: 94px;
    right: 8%;
    top: 53.2%;
  }
  .item:nth-child(8) {
    max-width: 129px;
    max-height: 129px;
    left: 44%;
    top: 74.7%;
  }
}

@media (max-width: 1000px) {
}

@media (max-width: 700px) {
}

@media (max-width: 520px) {
  .item {
    font-size: 1.4rem;
  }
  .item:nth-child(1) {
    max-width: 99px;
    max-height: 99px;
    top: 6.5%;
  }
  .item:nth-child(2) {
    max-width: 73px;
    max-height: 73px;
    left: 40%;
    top: 0.2%;
  }
  .item:nth-child(3) {
    max-width: 121px;
    max-height: 121px;
    right: 2.6%;
    top: -2.5%;
  }
  .item:nth-child(4) {
    max-width: 71px;
    max-height: 71px;
    top: 34.33%;
    left: 0;
  }
  .item:nth-child(5) {
    max-width: 76px;
    max-height: 76px;
    right: 0.9%;
    top: 31.5%;
  }
  .item:nth-child(6) {
    max-width: 96px;
    max-height: 96px;
    left: 5.9%;
    top: 64.5%;
  }
  .item:nth-child(7) {
    max-width: 61px;
    max-height: 61px;
    right: 8%;
    top: 53.2%;
  }
  .item:nth-child(8) {
    max-width: 84px;
    max-height: 84px;
    left: 44%;
    top: 74.7%;
  }
}

@media (max-width: 380px) {
  .item {
    font-size: 1.1rem;
  }
  .item:nth-child(1) {
    max-width: 84px;
    max-height: 84px;
    top: 6.5%;
  }
  .item:nth-child(2) {
    max-width: 62px;
    max-height: 62px;
    left: 40%;
    top: 0.2%;
  }
  .item:nth-child(3) {
    max-width: 102px;
    max-height: 102px;
    right: 2.6%;
    top: -2.5%;
  }
  .item:nth-child(4) {
    max-width: 60px;
    max-height: 60px;
    top: 34.33%;
    left: 0;
  }
  .item:nth-child(5) {
    max-width: 65px;
    max-height: 65px;
    right: 0.9%;
    top: 31.5%;
  }
  .item:nth-child(6) {
    max-width: 81px;
    max-height: 81px;
    left: 5.9%;
    top: 64.5%;
  }
  .item:nth-child(7) {
    max-width: 51px;
    max-height: 51px;
    right: 8%;
    top: 53.2%;
  }
  .item:nth-child(8) {
    max-width: 71px;
    max-height: 71px;
    left: 44%;
    top: 74.7%;
  }
}

@media (max-height: 520px) {
  .item {
    font-size: 1.4rem;
  }
  .item:nth-child(1) {
    max-width: 99px;
    max-height: 99px;
    top: 6.5%;
  }
  .item:nth-child(2) {
    max-width: 73px;
    max-height: 73px;
    left: 40%;
    top: 0.2%;
  }
  .item:nth-child(3) {
    max-width: 121px;
    max-height: 121px;
    right: 2.6%;
    top: -2.5%;
  }
  .item:nth-child(4) {
    max-width: 71px;
    max-height: 71px;
    top: 34.33%;
    left: 0;
  }
  .item:nth-child(5) {
    max-width: 76px;
    max-height: 76px;
    right: 0.9%;
    top: 31.5%;
  }
  .item:nth-child(6) {
    max-width: 96px;
    max-height: 96px;
    left: 5.9%;
    top: 64.5%;
  }
  .item:nth-child(7) {
    max-width: 61px;
    max-height: 61px;
    right: 8%;
    top: 53.2%;
  }
  .item:nth-child(8) {
    max-width: 84px;
    max-height: 84px;
    left: 44%;
    top: 74.7%;
  }
}

@media (max-height: 380px) {
  .item {
    font-size: 1.1rem;
  }
  .item:nth-child(1) {
    max-width: 84px;
    max-height: 84px;
    top: 6.5%;
  }
  .item:nth-child(2) {
    max-width: 62px;
    max-height: 62px;
    left: 40%;
    top: 0.2%;
  }
  .item:nth-child(3) {
    max-width: 102px;
    max-height: 102px;
    right: 2.6%;
    top: -2.5%;
  }
  .item:nth-child(4) {
    max-width: 60px;
    max-height: 60px;
    top: 34.33%;
    left: 0;
  }
  .item:nth-child(5) {
    max-width: 65px;
    max-height: 65px;
    right: 0.9%;
    top: 31.5%;
  }
  .item:nth-child(6) {
    max-width: 81px;
    max-height: 81px;
    left: 5.9%;
    top: 64.5%;
  }
  .item:nth-child(7) {
    max-width: 51px;
    max-height: 51px;
    right: 8%;
    top: 53.2%;
  }
  .item:nth-child(8) {
    max-width: 71px;
    max-height: 71px;
    left: 44%;
    top: 74.7%;
  }
}
