.Drawer {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 80%;
    max-width: 300px;
    padding: 100px 20px;
    box-sizing: border-box;
    background: #fff;
    z-index: 90;
    transform: translateX(0px);
    transition: transform .22s ease-in;

}

.Drawer.close {
    transform: translateX(  300px);
}

.Drawer ul {
    list-style:none;
    margin: 0;
    padding: 0;
}

.Drawer ul li {
    margin-bottom: 15px;
}

.Drawer ul li a {
 font-size: 3rem;
 text-decoration: none;
 background-color: #fff;
 position: relative;
 padding: 0 10px 20px;
 transition: opacity .3s;
}

.Drawer ul li a:hover,.active {
    color: blue;
}

@media (max-width: 1720px) {
    .Drawer ul li a {
        font-size: 2.5rem;
    }

}

@media (max-width:1300px) {
    .Drawer ul li a {
        font-size: 2rem;

    }
}
@media (max-width:1000px) {
    .Drawer ul li a {
        font-size: 2.5rem;
    }
}


@media (max-width:520px) {
    .Drawer ul li a {
        font-size: 2rem;
    }
    .Drawer { 
        width: 60%;
    }
}