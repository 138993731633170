.dmain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.divider {
  color: #d2d2d2;
  border: none;
  background-color: #d2d2d2;
  height: 1px;
}
.dividerBottom {
  color: #d2d2d2;
  border: none;
  background-color: #d2d2d2;
  height: 1px;
}
.wrapper {
  padding: 25px 40px 40px 40px;
}
.content {
  display: flex;
  justify-content: space-between;
}
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 394px;
  width: 100%;
  height: 100%;
  min-height: 550px;
}
.infoTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  font-weight: 500;
  white-space: normal;
}
.adress {
  font-size: 1.6rem;
}
.tags {
  display: flex;
  gap: 15px;
}

.infoBottom {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}

.infoBottomTitle {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
