.item:nth-child(9) img {
  position: absolute;
  left: 31.7%;
  top: 10.5%;
}

.item:nth-child(10) img {
  position: absolute;
  left: 8%;
  top: 50.6%;
}

.item:nth-child(11) img {
  position: absolute;
  left: 33%;
  top: 73.3%;
}

.item:nth-child(12) img {
  position: absolute;
  left: 60.5%;
  top: 66.2%;
}

.item:nth-child(13) img {
  position: absolute;
  left: 20.96%;
  top: 21.4%;
  border-radius: 50%;
  max-width: 418px;
  max-height: 418px;
  width: 100%;
  height: 100%; 
}

@media (max-width: 1720px) {
  .item img {
    width: 100%;
    height: 100%;
  }
  .item:nth-child(9) img {
    max-width: 76px;
    max-height: 76px;
    left: 31%;
    top: 12.5%;
  }
  .item:nth-child(10) img {
    max-width: 100px;
    max-height: 100px;
    left: 9.2%;
    top: 51.3%;
  }
  .item:nth-child(11) img {
    max-width: 81px;
    max-height: 81px;
    left: 33.7%;
    top: 73.6%;
  }
  .item:nth-child(12) img {
    max-width: 126px;
    max-height: 126px;
    left: 62%;
    top: 64.6%;
  }
  .item:nth-child(13) img {
    max-width: 350px;
    width: 100%;
    max-height: 350px;
    height: 100%;
  }
}

@media (max-width: 1300px) {
  .item:nth-child(9) img {
    max-width: 62px;
    max-height: 62px;
    left: 31.2%;
    top: 12.8%;
  }
  .item:nth-child(10) img {
    max-width: 82px;
    max-height: 82px;
    left: 9.2%;
  }
  .item:nth-child(11) img {
    max-width: 66px;
    max-height: 66px;
  }
  .item:nth-child(12) img {
    max-width: 105px;
    max-height: 105px;
  }
  .item:nth-child(13) img {
    max-width: 288px;
    max-height: 288px;
  }
}

@media (max-width: 1000px) {
}

@media (max-width: 700px) {
}

@media (max-width: 520px) {
  .item:nth-child(9) img {
    max-width: 40px;
    max-height: 40px;
    left: 31.2%;
    top: 12.8%;
  }
  .item:nth-child(10) img {
    max-width: 55px;
    max-height: 55px;
    left: 9.2%;
  }
  .item:nth-child(11) img {
    max-width: 42px;
    max-height: 42px;
  }
  .item:nth-child(12) img {
    max-width: 68px;
    max-height: 68px;
  }
  .item:nth-child(13) img {
    max-width: 187px;
    max-height: 187px;
  }
}

@media (max-width: 380px) {
  .item:nth-child(9) img {
    max-width: 34px;
    max-height: 34px;
    left: 31.2%;
    top: 12.8%;
  }
  .item:nth-child(10) img {
    max-width: 46px;
    max-height: 46px;
    left: 9.2%;
  }
  .item:nth-child(11) img {
    max-width: 36px;
    max-height: 36px;
  }
  .item:nth-child(12) img {
    max-width: 60px;
    max-height: 60px;
  }
  .item:nth-child(13) img {
    max-width: 159px;
    max-height: 159px;
  }
}

@media (max-height: 520px) {
  .item:nth-child(9) img {
    max-width: 40px;
    max-height: 40px;
    left: 31.2%;
    top: 12.8%;
  }
  .item:nth-child(10) img {
    max-width: 55px;
    max-height: 55px;
    left: 9.2%;
  }
  .item:nth-child(11) img {
    max-width: 42px;
    max-height: 42px;
  }
  .item:nth-child(12) img {
    max-width: 68px;
    max-height: 68px;
  }
  .item:nth-child(13) img {
    max-width: 187px;
    max-height: 187px;
  }
}

@media (max-height: 380px) {
  .item:nth-child(9) img {
    max-width: 34px;
    max-height: 34px;
    left: 31.2%;
    top: 12.8%;
  }
  .item:nth-child(10) img {
    max-width: 46px;
    max-height: 46px;
    left: 9.2%;
  }
  .item:nth-child(11) img {
    max-width: 36px;
    max-height: 36px;
  }
  .item:nth-child(12) img {
    max-width: 60px;
    max-height: 60px;
  }
  .item:nth-child(13) img {
    max-width: 159px;
    max-height: 159px;
  }
}
