.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  max-width: 220px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}
.title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
